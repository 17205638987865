#filter-container {
    background-color: var(--card-color);
    border-radius: 5px;
    margin-right: 25px;
    width: 245px;
    max-width: 245px;
    min-width: 245px;
    padding: 15px;
    
    display: flex;
    flex-direction: column;
}
@media screen and (max-width: 800px) {
    #filter-container {
        display: none;
    }
}
h1#filter-title {
    color: var(--accent-color-2-header);
    font-size: 2rem;
    margin-bottom: 15px;
}
input#filter-input {
    width: 100%;
    font-size: 0.875rem;
    margin-bottom: 15px;
}
div#radius-form-field {
    margin-bottom: 10px;
}
label#radius-label {
    margin-right: 0;
    color: var(--text-medium);
}
label#radius-label div {
    display: flex;
    justify-content: space-between;
}
label#radius-label p {
    margin-bottom: 0;
}
button#location-button {
    margin-bottom: 15px;
    border: 1px solid var(--chip-color);
}
div#filter-category-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
div#filter-category-container button {
    font-size: 0.6rem;
    padding: 7px;
    padding-left: 9px;
    padding-right: 9px;
    margin-right: 5px;
    margin-bottom: 5px;
}
label#category-label {
    color: var(--text-medium);
}


/* These are the styles for the slider */
input[type=range].slider {
    width: 100%;
    margin: 4.8px 0;
    background-color: transparent;
    -webkit-appearance: none;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  input[type=range].slider:focus {
    outline: none;
  }
  input[type=range].slider::-webkit-slider-runnable-track {
    background: #575757;
    border: 0;
    border-radius: 5px;
    width: 100%;
    height: 15.4px;
    cursor: pointer;
  }
  input[type=range].slider::-webkit-slider-thumb {
    margin-top: -4.8px;
    width: 22px;
    height: 25px;
    background: rgba(247, 174, 248, 0.93);
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    -webkit-appearance: none;
  }
  input[type=range].slider:focus::-webkit-slider-runnable-track {
    background: #5a5a5a;
  }
  input[type=range].slider::-moz-range-track {
    background: #575757;
    border: 0;
    border-radius: 5px;
    width: 100%;
    height: 15.4px;
    cursor: pointer;
  }
  input[type=range].slider::-moz-range-thumb {
    width: 22px;
    height: 25px;
    background: rgba(247, 174, 248, 0.93);
    border: 0;
    border-radius: 5px;
    cursor: pointer;
  }
  input[type=range].slider::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 4.8px 0;
    color: transparent;
    width: 100%;
    height: 15.4px;
    cursor: pointer;
  }
  input[type=range].slider::-ms-fill-lower {
    background: #545454;
    border: 0;
    border-radius: 10px;
  }
  input[type=range].slider::-ms-fill-upper {
    background: #575757;
    border: 0;
    border-radius: 10px;
  }
  input[type=range].slider::-ms-thumb {
    width: 22px;
    height: 25px;
    background: rgba(247, 174, 248, 0.93);
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }
  input[type=range].slider:focus::-ms-fill-lower {
    background: #575757;
  }
  input[type=range].slider:focus::-ms-fill-upper {
    background: #5a5a5a;
  }
  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
  @supports (-ms-ime-align:auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type=range].slider {
      margin: 0;
      /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
  }
  