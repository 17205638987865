html {
    font-size: 16px;
}

@media screen and (min-width: 320px) {
    html {
        font-size: calc(16px + 6 * ((100vw - 320px) / 680));
    }
}

@media screen and (min-width: 1000px) {
    html {
        font-size: 22px;
    }
}

:root {
    --off-white: #fcfcfc;
    --bg-color: #272727;
    --card-color: #414141;
    --card-color-transparent: #41414196;
    --hover-color: #4e4e4e;
    --chip-color: #575757;
    --input-color: #ffffff;
    --accent-color-1-button: #92dce5;
    --accent-color-1-button-hover: #99cdd4;
    --accent-color-1-dark: #578a91;
    --accent-color-1-muted: rgb(205, 241, 239);
    --accent-color-2-header: #f7aef8;
    --accent-color-2-muted: #f1cdd7;
    --accent-color-2-dark: #4d4144;
    --accent-color-1-dark-opacity: #92dbe523;
    --text-high: rgba(255, 255, 255, 0.87);
    --text-medium: rgba(255, 255, 255, 0.6);
    --text-low: #414141;
    --text-dark: #272727;
    --text-disabled: rgba(255, 255, 255, 0.38);
    --error-bg: #eb879a;
    --error-bg-hover: #d47889;
    --error-text: #582b33;
    --success-bg: #6cc960;
    --success-text: #325b24;
    --warning-bg: #fff3cd;
    --warning-text: #b66c04;
    --shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
        0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
        0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

html {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Nunito", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.low-text {
    font-size: 0.785rem;
    color: var(--text-medium);
}
.link {
    color: var(--accent-color-1-button);
}

.link:hover {
    color: var(--accent-color-1-button-hover);
}

.error-link {
    font-weight: bold;
    color: #000000;
}

.error-link:hover {
    font-weight: bold;
    color: #000000;
}

.alert-bg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #000000;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
}

.error-bg {
    background-color: var(--error-bg);
}

.success-bg {
    background-color: var(--success-bg);
}

.success-text {
    color: var(--success-text);
}

.warning-bg {
    background-color: var(--warning-bg);
}

.warning-text {
    color: var(--warning-text);
}

.alert-icon {
    font-size: 1.375rem;
    min-width: 1.375rem;
    margin-left: 10px;
    vertical-align: top;
}

.check-icon {
    /* special case because the icon is so small */
    font-size: 1.5rem;
}

label {
    margin-bottom: 0;
    margin-right: 10px;
}

.tooltip-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3px;
}

input {
    color: var(--input-color);
    background-color: var(--chip-color);
    padding: 10px;
    font-size: 1rem;
    border: 1px solid transparent;
    border-radius: 5px;
}

input[type="time"]::-webkit-clear-button {
    color: var(--text-high);
}

input:focus {
    border: 1px solid var(--accent-color-2-header);
}

button,
a.button-link {
    background-color: var(--accent-color-1-button);
    color: var(--text-dark);
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 0.785rem;
}

button.link-button {
    text-decoration: underline;
    background-color: transparent;
    border: none;
    color: var(--accent-color-1-button);
}
button.link-button:hover {
    background-color: transparent;
    color: var(--accent-color-1-button-hover);
}

@media screen and (max-width: 700px) {
    button,
    a.button-link {
        font-size: 0.9rem;
    }
}

button:hover,
a.button-link:hover {
    background-color: var(--accent-color-1-button-hover);
    color: var(--text-dark);
    cursor: pointer;
}

button:disabled {
    background-color: var(--accent-color-1-button-hover);
}

.button-outline {
    background-color: transparent;
    color: var(--accent-color-2-header);
    border: 1px solid var(--accent-color-2-header);
}

.button-outline:hover {
    color: var(--text-dark);
    background-color: var(--accent-color-2-header);
}

.button-outline-2,
.button-outline-2.button-link {
    background-color: transparent;
    color: var(--accent-color-1-button);
    border: 1px solid var(--accent-color-1-button);
    padding-top: 9px;
    padding-bottom: 9px;
}

.button-outline-2:hover,
.button-outline-2.button-link:hover {
    color: var(--text-dark);
    background-color: var(--accent-color-1-button-hover);
}

button.delete-button {
    background-color: var(--error-bg);
    color: var(--error-text);
}

button.delete-button:hover {
    background-color: var(--error-bg-hover);
}

.form {
    margin-bottom: 10px;
}

.panel-text {
    margin-top: 0.1rem;
    margin-bottom: 0;
}

.section {
    width: 400px;
    max-width: 97%;
    color: var(--text-medium);
    background-color: var(--bg-color);
    padding: 20px;
    border-radius: 5px;
    height: min-content;
}

.section-title {
    color: var(--accent-color-2-header);
    padding-bottom: 10px;
    font-weight: 700;
    font-size: 2rem;
}

/* Used for having multiple items in the section title container */

.row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}
.row-container-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.form-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-field label {
    font-size: 0.875rem;
}

.text-high {
    color: var(--text-high);
}

.panel {
    border-radius: 5px;
    background-color: var(--card-color);
    padding: 20px;
    width: 100%;
    color: var(--text-medium);
    margin-bottom: 20px;
}

.panel.fit {
    padding: 0;
}

.panel.panel-small {
    width: min-content;
    min-width: 300px;
}
.notification-ball {
    background-color: var(--accent-color-2-header);
    border-radius: 1000px;
    height: 15px;
    width: 15px;
    position: absolute;
    top: 9px;
    right: -25px;
}
.last-text {
    margin-bottom: 0;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
}

.truck-table {
    border-spacing: 1em 0.5em;
    padding: 0 2em 1em 0;
    width: 100%;
}

.table-heading {
    color: var(--text-medium);
    font-size: 1rem;
    line-height: 1;
    margin: 0;
    width: 15rem;
    padding-bottom: 4px;
}

.table-heading.fit {
    width: 1%;
    text-align: center;
    white-space: nowrap;
}

.table-entry {
    font-weight: 500;
    font-size: 1rem;
    color: var(--text-high);
}

.table-row {
    margin-top: 10px;
    border-top: 1px solid var(--text-medium);
}

/* .table-row:hover {
  background-color: var(--chip-color)
} */

.truck-table td {
    padding: 10px;
    padding-left: 0;
    padding-right: 20px;
}

.truck-table .status-pill-td {
    padding-right: 0;
    height: 100%;
}

.truck-table .dropdown-td {
    padding-left: 20px;
    float: right;
    max-width: 20rem;
}

/* For create menus */

.modal-bg {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    /* just in case */
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
}

.modal-box {
    max-width: 800px;
    margin-top: 8%;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 100px;
    height: max-content;
    background-color: var(--card-color);
    outline: none;
}

.category-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
}

button.tag {
    border-radius: 1000px;
    padding: 10px;
    background-color: var(--chip-color);
    color: var(--text-high);
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 0.785rem;
}

/* Makes fixed width buttons that look less like tags/filters */

button.tag.fix {
    border-radius: 5px;
    width: 60px;
}

button.tag.active {
    color: var(--text-dark);
    background-color: var(--accent-color-2-header);
}

button.tag:disabled {
    background-color: var(--card-color);
    color: var(--text-disabled);
}

button.secondary {
    background-color: transparent;
    color: var(--text-high);
    display: flex;
    flex-direction: row;
    align-items: center;
}

button.secondary:hover {
    background-color: var(--chip-color);
    border-radius: 5px;
}

.create-form {
    padding-left: 20px;
    padding-right: 20px;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: all 150ms ease-in-out;
}

.ReactModal__Content {
    /* transform: translateY(100px); */
    transition: all 150ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
    overflow: hidden;
}

.Toastify__toast {
    border-radius: 5px !important;
    box-shadow: var(--shadow) !important;
}

.Toastify__toast--success {
    background-color: var(--success-bg) !important;
    color: var(--success-text) !important;
}

.Toastify__close-button {
    color: var(--success-text) !important;
}

@media screen and (max-width: 900px) {
    .create-form {
        padding-left: 0;
        padding-right: 0;
    }
    .form-field label {
        font-size: 1rem;
    }
    .table-heading {
        width: min-content;
    }
}

@media screen and (max-width: 700px) {
    .mobile-hide {
        display: none;
    }
}

/* checkbox styles */
.b-contain *,
.b-contain *::before,
.b-contain *::after {
    box-sizing: content-box !important;
}

.b-contain input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.b-contain span {
    line-height: 1.54;
    font-size: 1rem;
    font-family: inherit;
}

.b-contain {
    display: table;
    position: relative;
    padding-left: 1.8rem;
    cursor: pointer;
    margin-bottom: 0.5rem;
}

.b-contain input[type="checkbox"] ~ .b-input {
    position: absolute;
    top: -18px;
    left: -15px;
    height: 1.25rem;
    width: 1.25rem;
    background: transparent;
    transition: background 250ms;
    border: 3px solid var(--text-medium);
    border-radius: 0.125rem;
}

.b-contain input[type="checkbox"] ~ .b-input::after {
    content: "";
    position: absolute;
    display: none;
    left: 0.45rem;
    top: 0.18rem;
    width: 0.25rem;
    height: 0.6rem;
    border: solid rgba(255, 255, 255, 1);
    border-width: 0 2px 2px 0;
    transition: background 250ms;
    transform: rotate(45deg);
}

.b-contain input[type="radio"] ~ .b-input::after {
    content: "";
    position: absolute;
    display: none;
    left: 0.25rem;
    top: 0.25rem;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 2rem;
    background: rgba(255, 255, 255, 1);
    transition: background 250ms;
}

.b-contain input:disabled ~ .b-input::after {
    border-color: rgba(135, 149, 161, 1);
}

.b-contain input:checked ~ .b-input::after {
    display: block;
}

.b-contain:hover input ~ .b-input,
.b-contain input:focus ~ .b-input {
    background: rgb(231, 238, 243);
}

.b-contain input:focus ~ .b-input {
    box-shadow: 0 0 0 2px var(--accent-color-2-muted);
}

.b-contain input:checked ~ .b-input {
    background: var(--accent-color-2-header);
    border-color: var(--accent-color-2-header);
}

.b-contain input[type="checkbox"]:disabled ~ .b-input {
    background: rgba(241, 245, 248, 1);
    border-color: rgba(184, 194, 204, 1);
    opacity: 0.6;
    cursor: not-allowed;
}

.b-contain input:checked:focus ~ .b-input,
.b-contain:hover input:not([disabled]):checked ~ .b-input {
    background: var(--accent-color-2-muted);
    border-color: var(--accent-color-2-muted);
}

.b-contain .b-input::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 3rem;
    height: 3rem;
    margin-left: -0.85rem;
    margin-top: -0.85rem;
    background: var(--accent-color-2-header);
    border-radius: 2rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
}

@keyframes b-ripple {
    0% {
        transform: scale(0);
    }

    20% {
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

@keyframes b-ripple-duplicate {
    0% {
        transform: scale(0);
    }

    30% {
        transform: scale(1);
    }

    60% {
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

.b-contain input + .b-input::before {
    animation: b-ripple 250ms ease-out;
}

.b-contain input:checked + .b-input::before {
    animation-name: b-ripple-duplicate;
}

.b-contain .b-input::before {
    visibility: hidden;
}

.b-contain input:focus + .b-input::before {
    visibility: visible;
}

.b-contain:first-child .b-input::before {
    visibility: hidden;
}
