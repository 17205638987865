.gallery-image {
    height: 50px;
    width: 50px;
    object-fit: cover;
}

table th.gallery-table-header {
    color: var(--text-medium);
    padding-bottom: 0;
    padding-top: 0;
    border: none;
}

.gallery-table-entry {
    color: var(--text-high);
}

.gallery-table-row {
    border-top: 1px solid var(--text-medium);
}

.gallery-table-row td {
    vertical-align: middle;
}

.gallery-table-row td.gallery-table-name:hover {
    text-decoration: underline;
}
.table-checkbox-entry label {
    margin: auto;
}
