.landing-container {
    width: 100vw;
    min-height: 500px;
    height: 100vh;
    max-height: 1400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 10px solid var(--accent-color-1-dark);
    background-color: var(--accent-color-2-muted);
    padding-top: 80px;
    padding-left: 70px;
    padding-right: 70px;
}
.first-section {
    padding-top: 0;
    padding-right: 0;
}
.for-truck-owner {
    background-color: var(--bg-color);
}
.app-showcase {
    background-color: var(--off-white);
    padding-top: 0;
}
img.background {
    min-height: 500px;
    height: 100%;
    max-height: 1400px;
}
.app-showcase img.background {
    height: 90%;
}
.foodtruck-svg-container {
    width: 500px;
    padding: 7%;
    padding-left: 0;
}
.foodtruck-svg-container .circle-icon {
    width: 20rem;
    height: 20rem;
}
.for-truck-owner {
    border-bottom: none;
}
.for-truck-owner .truck-owner-content {
    background-color: transparent;
    color: var(--accent-color-2-header);
}
.for-truck-owner .truck-owner-content h1 {
    color: var(--acent-color-2-header);
}
.truck-owner-cta-container {
    margin-top: 15px;
}
.truck-owner-cta-container a {
    margin-right: 20px;
    font-size: 22px;
}
.showcase-content {
}
h1 {
    font-size: 3.5rem;
    font-weight: bold;
    color: var(--accent-color-2-dark);
    margin-bottom: 10px;
}
.landing-content h1 .special-word {
    color: var(--accent-color-1-dark);
}
.subtitle {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0;
}
.link.dark {
    color: var(--accent-color-1-dark);
}
.landing-container .mobile-button-wrapper {
    display: flex;
    flex-direction: row;
}
.landing-container img.mobile-button {
    max-width: 100%;
    height: auto;
}
#google-store {
    width: 180px;
}
#apple-store {
    /* Weird values because apple store logo is larger */
    box-sizing: border-box;
    margin: 8.2%;
    margin-left: 0;
    margin-right: 0;
    width: 142px;
}
.showcase-content .subtitle {
    margin-bottom: 15px;
}
.cta {
    font-weight: 500;
    font-size: 20px;
}

@media screen and (max-width: 1160px) {
    /* This will hide the landing image at this width */
    .landing-img {
        display: none;
    }
    .landing-content {
        width: 100%;
    }
    .landing-content {
        padding-bottom: 0;
    }
    .landing-container {
        height: min-content;
        min-height: min-content;
        padding-bottom: 30px;
        padding-top: 30px;
        padding-left: 5%;
        padding-right: 5%;
    }
    .first-section {
        /* Avoids the navbar */
        padding-top: 80px;
    }
    .subtitle {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 1095px) {
    /* This will tweak the img and content width of the app-showcase to fit 
        the content better */
    .app-showcase img {
        width: 45%;
        height: auto;
        max-height: 100%;
        object-fit: contain;
        object-position: top;
        min-height: 300px;
    }
    .showcase-content h1 {
        font-size: 2.5rem;
    }
    .truck-owner-content h1 {
        font-size: 2.5rem;
    }

    /* Tweaks the img and content width of the for-food-truck section for mid
        screen sizes */
    .for-truck-owner {
        height: min-content;
        min-height: min-content;
    }
    .foodtruck-svg-container {
        width: 50%;
    }
    .foodtruck-svg-container .circle-icon {
        width: 14rem;
        height: 14rem;
        margin-bottom: 0;
    }
}
/* This is more or less for mobile screens */
@media screen and (max-width: 740px) {
    .landing-container,
    .for-truck-owner {
        flex-direction: column;
        align-items: center;
    }
    .app-showcase img {
        width: 70%;
    }
    .foodtruck-svg-container {
        padding-right: 0;
        width: min-content;
    }
}
