#sidebar {
    display: flex;
    flex-direction: column;
    width: min-content;
    background-color: var(--card-color);
}
#sidebar img {
    width: 100%;
    height: auto;
}
#sidebar a img {
    padding: 20px;
}
.icon-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 3px;
    padding-top: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 100%;
    color: var(--text-high);
    font-size: 1rem;
    cursor: pointer;
    box-sizing: border-box;
}
.icon-container:hover {
    background-color: var(--hover-color);
}
.icon-container.active {
    background-color: var(--chip-color);
    border-right: 3px solid var(--accent-color-1-button);
    padding-right: 17px;
}
.sidebar-icon {
    margin-right: 15px;
}
.icon-container p {
    margin-bottom: 0;
}
#toggler-container {
    display: none;
    border-bottom: none;
}
#toggler-container .navbar-toggler {
    background-color: transparent;
}
/* Turn the sidebar into a top drawer menu */
@media screen and (max-width: 825px) {
    #dashboard-container {
        /* Puts the nav bar on top of the content */
        flex-direction: column;
    }
    #sidebar {
        width: 100%;
    }
    #sidebar #home-link {
        display: none;
    }
    .icon-container {
        font-size: 1.25rem;
    }
    .icon-container.active {
        padding-right: 20px;
        border-right: none;
        padding-left: 17px;
        border-left: 3px solid var(--accent-color-1-button);
    }
    #toggler-container {
        background-color: var(--card-color);
        display: block;
    }
}
@media screen and (min-width: 826px) {
    #sidebar-collapse {
        display: block;
    }
}