.column-entry {
    width: 100%;
    padding: 5px;
    padding-left: 15px;
    font-size: 0.8rem;
}
@media screen and (max-width: 600px) {
    .column-entry {
        font-size: 1rem;
    }
}
.column-entry:hover {
    cursor: pointer;
    background-color: var(--hover-color);
}
.column-entry.selected {
    background-color: var(--chip-color);
}
.column-entry p {
    margin: 0;
    color: var(--text-medium);
}
.column-entry.active {
    background-color: var(--chip-color);
}
.column-entry.active p {
    color: var(--text-high);
}