.new-rule {
    margin-bottom: 15px;
    width: max-content;
}
#rule-button-container {
    display: flex;
    flex-direction: row;
}
#rule-button-container button {
    margin-right: 15px;
}