.availability-info-container {
    display: grid;
    grid-template-columns: max-content max-content;
    color: var(--text-high);
    font-size: 1rem;
}
.availability-info-container p {
    margin-bottom: 6px;
}
.hourline-day {
    margin-right: 0.9rem;
}
.description-field {
    color: var(--text-high);
    font-size: 0.9rem;
}
.price-field {
    color: var(--text-high);
    font-size: 0.9rem;
}
.calories-field {
    color: var(--text-high);
    font-size: 0.9rem;
}
.entry-info-button-container {
    margin-bottom: 10px;
}
.info-group .label {
    margin-bottom: 5px;
}