#cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 25px;
}
div.empty-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
div.empty-list {
    color: var(--text-high);
    text-align: center;
}