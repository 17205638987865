.column {
    color: var(--accent-color-2-header);
    min-height: 300px;
    min-width: 250px;
    border-right: 1px solid var(--text-disabled);
}
.column:last-child {
    border-right: none;
}
.col-title {
    border-bottom: 1px solid var(--text-disabled);
    padding: 5px;
    padding-left: 10px;
    padding-right: 0;
    margin-bottom: 0;
    font-size: 1.25rem;
}
.empty-list-container {
    width: 100%;
    height: 200px; /* Ugh I know... TODO: fix this and that .col-title is a sibling*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}
.empty-list-text {
    color: var(--text-high);
    text-align: center;
    font-size: 0.8rem;
}
.entry-add-button {
    /* Most of these are resets to default styles */
    background-color: transparent;
    color: var(--accent-color-1-button);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.entry-add-button:hover {
    color: var(--accent-color-1-button);
}
@media screen and (max-width: 600px) {
    .col-title {
        font-size: 1.5rem;
    }
    .empty-list-text {
        font-size: 1rem;
    }
}