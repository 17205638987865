.status-pill {
    border-radius: 1000px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    letter-spacing: 1px;
    font-size: 14px;
    width: max-content;
}
.success {
    background-color: #c3e6c3cc;
    color: var(--success-text);
}
.warning {
    border: 1px solid #eeee84;
    color: #eeee84;
}
.truck-table-name:hover {
    text-decoration: underline;
    cursor: pointer;
}
