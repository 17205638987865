.navbar-brand img {
    width: 170px;
    margin-bottom: 0.13rem;
}
.navbar {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.navbar.scrolled {
    background-color: var(--accent-color-2-muted);
    transition: all 200ms linear;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
        0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
        0 16px 16px rgba(0, 0, 0, 0.12);
}
.nav-item.active .nav-link {
    font-weight: bold;
}
.nav-link {
    font-size: 0.8rem;
    transition: all 0.2s ease-in-out;
    color: var(--text-dark);
}
.navbar-toggler {
    background-color: var(--accent-color-2-muted);
}
.navbar-toggler:hover {
    background-color: var(--accent-color-2-muted);
}
@media screen and (max-width: 768px) {
    .navbar {
        background-color: var(--accent-color-2-muted);
        border-bottom: 4px solid var(--accent-color-2-dark);
    }
    .navbar.scrolled {
        background-color: var(--accent-color-2-muted);
    }
}
@media screen and (max-width: 740px) {
    .navbar-brand img {
        margin-bottom: 0;
    }
}
