#user-location-button {
    display: flex;
    align-items: center;
    flex-direction: row;
}
#user-location-button #down-arrow-icon {
    margin-left: 10px;
}
#user-location-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#user-location-form .alert-bg {
    font-size: 0.875rem;
}
#user-location-gps-button {
    margin: 10px
    /* border: 1px solid var(--chip-color); */
}
#user-location-input {
    font-size: 0.875rem;
    width: 15ch;
    margin: 10px;
    margin-bottom: 0;
}
#zip-container {
    display: flex;
    flex-direction: row;
    height: min-content;
}
#zip-container input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
    margin-top: 0;
}
#zip-container button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 0;
}