.about-container {
    background-color: var(--bg-color);
}
.about-top-section {
    width: 100%;
    height: 50vw;
    max-height: 400px;
    background-color: var(--accent-color-2-muted);
    position: absolute;
    top: 0;
}
.about-bottom-section {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 80px;
    
    position: relative;
    z-index: 1;
    
}
.about-content {
    color: var(--text-high);
    font-size: 1rem;
    width: 60ch;
    max-width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;

    padding-top: 80px;
}
.about-bottom-section img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 40px;
}
.about-title {
    color: var(--accent-color-2-header);
    font-size: 2rem;
    font-weight: bold;
    align-self: flex-start;
    margin-bottom: 20px;
}
@media screen and (max-width: 500px) {
    .about-text {
        font-size: 1.25rem;
    } 
}