#dashboard-container {
    display: flex;
    background-color: var(--bg-color);
    min-height: 100%;
}
#content {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.circle-icon {
    display: flex;
    justify-content: center;
    align-content: center;
    background: var(--accent-color-1-dark-opacity);
    width: 100%;
    height: 100%;
    width: 17rem;
    height: 17rem;
    border-radius: 50%;
    padding: 30px;
    margin-bottom: 30px;
}
.circle-icon img {
    width: 100%;
}
#empty-list-container {
    color: var(--text-high);
}
#empty-list-container h2 {
    margin-bottom: 40px;
}
.loading-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading-background {
    width: min-content;
}
@media screen and (max-width: 600px) {
    .circle-icon {
        width: 15rem;
        height: 15rem;
    }
}
