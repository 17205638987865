#trucks-list-container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    min-height: 100vh;
    padding: 25px;

    background-color: var(--bg-color);
}
#card-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
#title-card {
    width: 100%;
    border-radius: 5px;
    background-color: var(--card-color);
    padding: 15px;
    margin-bottom: 25px;
}
#title-card h1 {
    color: var(--accent-color-2-header);
    font-size: 2rem;
    margin-bottom: 0;
}
div#title-card div.row-container {
    align-items: center;
}
