div.contact-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
a.contact-info {
    color: var(--accent-color-2-header);
    text-decoration: underline;
    margin-bottom: 15px;
}
a.contact-info.large {
    font-size: 1.5rem;
}
a.contact-info .contact-icon {
    font-size: 2rem;
    margin-right: 10px;
}
