.hour-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}
.hour-input-wrapper > p {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0;
}
.rule-container {
    margin-bottom: 5px;
}