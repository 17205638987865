.time-selectors-container,
.time-select-container {
    margin-left: 10px;
}

.status-checkbox-container {
    margin-left: 40px;
}

.status-checkbox-container *:first-child,
.time-selectors-container *:first-child {
    margin-right: 7px;
}

.time-select-container *:last-child {
    margin-left: 7px;
}

.day-container {
    margin-bottom: 20px;
}

.day-label {
    width: 5rem;
}
