footer {
    background-color: var(--accent-color-2-muted);
    border-top: 5px solid var(--accent-color-1-button);
}
footer a {
    color: var(--text-low);
}
#footer-pages {
    display: flex;
    flex-direction: column;
}
#footer-logo {
    width: 100%;
}
