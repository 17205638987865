.trucks-card {
    width: 100%;
    max-width: 400px;
    height: 400px;

    background-color: var(--card-color);
    border-radius: 5px;

    display: flex;
    flex-direction: column;
}
.truck-card-banner {
    object-fit: none;
    object-position: center;
    width: 100%;
    height: 150px;
    border-radius: 5px 5px 0 0;
    background-color: var(--chip-color);
}
.truck-card-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
h2.truck-card-name {
    color: var(--text-high);
    font-size: 1.5rem;
}
div.truck-card-desc-container {
    max-height: 3rem;
    overflow: hidden;
    position: relative;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    margin-bottom: 10px;
}
p.truck-card-desc {
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--text-medium);
}
div.truck-card-food-cat-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;

    background-image: 
	
    /* Shadows */ linear-gradient(
            to right,
            var(--card-color),
            var(--card-color)
        ),
        linear-gradient(to right, var(--card-color), var(--card-color)),
        /* Shadow covers */ linear-gradient(to right, rgba(0, 0, 0, 0.5), #57575700),
        linear-gradient(to left, rgba(0, 0, 0, 0.5), #57575700);

    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: var(--card-color);
    background-size: 20px 100%, 20px 100%, 30px 100%, 30px 100%;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
}
p.truck-card-food-category {
    color: var(--text-high);
    border-radius: 1000px;
    background-color: #ffffff0c;
    background-color: var(--accent-color-1-dark-opacity);
    font-size: 0.675rem;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 7px;
    margin-bottom: 0;
}
div.truck-card-distance-container {
    margin-top: auto;
    width: 100%;
}
p.truck-card-distance {
    color: var(--text-medium);
    margin-bottom: 0;
    font-size: 0.875rem;
}
