.owner-container {
    margin-top: 5rem;
}
.landing-register-form {
    background-color: var(--bg-color);
    border-radius: 5px;
    padding: 15px;
    color: var(--text-medium);
}
.landing-register-form h3 {
    color: var(--accent-color-2-header);
}
.business-name {
    color: #d861bf;
}
.business-name:hover {
    color: #ce4bb2;
}
